










































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'UofTQuiz4Question1',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        A: null as null | string,
        B: null as null | string,
        C: null as null | string,
        D: null as null | string,
      },
      imagePrefix: '/img/assignments/UofT/CHMB41H/2023/Quiz4/',
      mechAttachments: [] as File[],
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 3;
    },
    partIIgrades(): string {
      if (this.version === 1) {
        return '[4 points]';
      } else if (this.version === 2) {
        return '[5 points: 3 for a) + 2 for b)]';
      } else {
        return '[3 points: 0.5 for each arrow + 1 for resonance structure(s)]';
      }
    },
    numParts() {
      if (this.version === 1) {
        return 4;
      } else if (this.version === 2) {
        return 3;
      } else {
        return 4;
      }
    },
    choices(): string[] {
      return ['A', 'B', 'C', 'D'];
    },
    parts(): string[] {
      if (this.version === 1) {
        return ['A', 'B', 'C', 'D'];
      } else if (this.version === 2) {
        return ['A', 'B', 'C'];
      } else {
        return ['A', 'B', 'C', 'D'];
      }
    },
    images(): string[] {
      return this.parts.map(
        (l: string) => this.imagePrefix + 'Question1/part' + l + '-v' + this.version + '.png'
      );
    },
    choiceImages(): string[][] {
      return [
        this.parts.map(
          (l: string) =>
            this.imagePrefix + 'Question1/part' + l + '-v' + this.version + '-choiceA' + '.png'
        ),
        this.parts.map(
          (l: string) =>
            this.imagePrefix + 'Question1/part' + l + '-v' + this.version + '-choiceB' + '.png'
        ),
        this.parts.map(
          (l: string) =>
            this.imagePrefix + 'Question1/part' + l + '-v' + this.version + '-choiceC' + '.png'
        ),
        this.parts.map(
          (l: string) =>
            this.imagePrefix + 'Question1/part' + l + '-v' + this.version + '-choiceD' + '.png'
        ),
      ];
    },
    part2Image(): string {
      return this.imagePrefix + `Question2/v${this.version}.png`;
    },
    part2ImageWidth(): number {
      if (this.version === 1) {
        return 483;
      } else if (this.version === 2) {
        return 504;
      } else {
        return 382;
      }
    },
    attachments(): File[] {
      return [...this.mechAttachments];
    },
  },
});
